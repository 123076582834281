<template>
  <v-card flat class="general-supports" :class="{ 'd-grid': mdAndUp }">
    <v-card-text class="px-0">
      <Split
        style="min-height: 100vh"
        :direction="!mdAndUp ? 'vertical' : 'horizontal'"
      >
        <SplitArea :size="45" :min-size="350" class="border-1 rounded-10">
          <v-card min-height="100vh" class="fill-height">
            <v-chip-group row v-model="filterTags" multiple>
              <v-chip
                label
                :disabled="fetching"
                :value="tag.id"
                v-for="tag in generalTags"
                :key="tag.id"
                filter
                class="ma-1"
                color="primary"
                outlined
              >
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
            <v-sheet
              class="pa-2 d-flex align-center justify-space-between flex-nowrap"
            >
              <v-btn
                color="primary"
                @click="report_bug_dialog = true"
                depressed
                class="text-none ma-1"
              >
                <v-icon left>mdi-plus-circle</v-icon> New Ticket
              </v-btn>
              <v-text-field
                :append-icon="
                  !fetching
                    ? `mdi-clipboard-search-outline`
                    : `mdi-spin mdi-loading`
                "
                hide-details="auto"
                dense
                class="ma-1"
                clear-icon="mdi-close-circle-outline"
                clearable
                v-model="keyword"
                @keydown.enter="submit"
                @click:append="handleSearch(keyword)"
                placeholder="Search for..."
                outlined
              ></v-text-field>
            </v-sheet>
            <v-divider />
            <PageLoader min-height="100vh" v-if="fetching"></PageLoader>
            <Empty
              min-height="100vh"
              v-if="!fetching && !tickets.length"
              :headline="`No tickets found!`"
            ></Empty>
            <v-list
              class="tickets-list"
              dense
              v-if="!fetching && tickets.length"
            >
              <v-list-item
                :class="{ active: isActive(ticket) }"
                @click="set_activeTicket(ticket)"
                v-for="ticket in tickets"
                :key="ticket.id"
              >
                <v-list-item-avatar class="mr-2">
                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img
                        v-bind="attrs"
                        v-on="on"
                        contain
                        :src="ticket.creator.image_url"
                        :lazy-src="settings.loader"
                      ></v-img>
                    </template>
                    <span> {{ ticket.creator.username }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">
                    {{ ticket.slug }} : {{ ticket.title | ucwords }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption grey--text">
                    Posted {{ ticket.created_at | from_now }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="primary--text fw-700">
                  <v-chip
                    small
                    outlined
                    class="status-chip"
                    :class="slugify(ticket.status)"
                  >
                    {{ ticket.status | ucwords }}
                  </v-chip>
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
            <v-card-actions
              v-if="hasMoreTickets"
              class="d-flex align-center justify-center"
            >
              <v-btn
                color="primary"
                depressed
                :loading="fetching_more"
                class="text-none rounded-10 px-5"
                :disabled="fetching_more || fetching"
                @click="fetchMoreTickets"
              >
                Load more
              </v-btn>
            </v-card-actions>
          </v-card>
        </SplitArea>
        <SplitArea :size="55" :min-size="420" class="border-1 rounded-10">
          <Empty
            headline="No selected ticket"
            min-height="100vh"
            v-if="!activeTicket"
          ></Empty>
          <ViewTicket
            flat
            class="fill-height"
            v-else
            min-height="100vh"
            :item="activeTicket"
            @deleted="set_activeTicket(null)"
          ></ViewTicket>
        </SplitArea>
      </Split>
    </v-card-text>
    <ReportBugDialog
      @save="handleSaveTicket"
      v-model="report_bug_dialog"
    ></ReportBugDialog>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ViewTicket from '../components/ViewTicket.vue'
import ReportBugDialog from '../components/ReportBugDialog.vue'
export default {
  components: { ViewTicket, ReportBugDialog },
  data: () => ({
    keyword: null,
    report_bug_dialog: false
  }),
  created() {
    this.fetchTickets()
    this.$store.watch(
      (state) => {
        return this.$store.getters['supports/filters_tags']
      },
      (val) => {
        this.$store.dispatch('supports/fetchTickets')
      },
      { deep: true }
    )
  },
  watch: {
    '$route.params.id': {
      handler: function (val) {
        if (val && !['updates-and-fixes', 'management', 'faqs'].includes(val)) {
          this.fetchTicketBySlug(val)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('supports', [
      'tickets',
      'fetching',
      'fetching_more',
      'tags',
      'hasMoreTickets',
      'filters_tags',
      'filters_search',
      'generalTags',
      'activeTicket',
      'tag_counts'
    ]),
    filterTags: {
      get() {
        return this.$store.getters['supports/filters_tags']
      },
      set(val) {
        this.set_filters_search(null)
        this.keyword = null
        this.$store.commit('supports/set_filters_tags', val)
      }
    }
  },
  methods: {
    ...mapMutations('supports', [
      'set_activeTicket',
      'set_filters_tags',
      'set_filters_search',
      'set_activeTicket'
    ]),
    ...mapActions('supports', [
      'fetchTickets',
      'fetchMoreTickets',
      'searchTickets',
      'submitTicket',
      'fetchTicketBySlug'
    ]),
    handleSaveTicket(payload) {
      this.submitTicket({
        payload: payload,
        cb: () => {
          this.report_bug_dialog = false
          this.appSnackbar('Ticket submitted')
        }
      })
    },
    isActive(ticket) {
      return this.activeTicket && this.activeTicket.id === ticket.id
    },
    submit(event) {
      event.preventDefault()
      this.handleSearch(this.keyword)
    },
    handleSearch(keyword) {
      this.set_filters_search(keyword)
      this.searchTickets(keyword)
    }
  }
}
</script>

<style lang="scss" scoped>
.general-supports {
  .tickets-list {
    .active {
      background: $blue;
      * {
        color: #ffffff !important;
      }
    }
  }
  .status-chip {
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.in-progress {
      color: blue;
      border-color: blue;
    }
    &.open {
      color: rgb(8, 123, 177);
      border-color: rgb(8, 123, 177) !important;
    }
    &.closed {
      color: $red;
      border-color: $red;
    }
    &.archived {
      color: maroon;
      border-color: maroon;
    }
    &.fixed {
      color: darkgreen;
      border-color: darkgreen;
    }
  }
}
</style>
